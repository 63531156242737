<template>
  <centered-layout>
    <card-with-logo>
      <template #title>
        {{ labels.LBL_RESET_PASSWORD }}
      </template>
      <el-form ref="resetPasswordForm" :model="reset" :rules="rules">
        <el-form-item>
          <el-input
            v-model="reset.email"
            type="email"
            prefix-icon="el-icon-message"
            :placeholder="labels.PH_EMAIL"
            disabled
            @keyup.native.enter="onSubmit('resetPasswordForm')"
          />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="reset.password"
            prefix-icon="el-icon-lock"
            :placeholder="labels.PH_NEW_PASSWORD"
            show-password
            @keyup.native.enter="onSubmit('resetPasswordForm')"
          />
        </el-form-item>
        <el-form-item prop="password_confirmation">
          <el-input
            v-model="reset.password_confirmation"
            prefix-icon="el-icon-lock"
            :placeholder="labels.PH_NEW_PASSWORD"
            show-password
            @keyup.native.enter="onSubmit('resetPasswordForm')"
          />
        </el-form-item>
      </el-form>

      <el-row type="flex">
        <el-button
          type="primary"
          @click="onSubmit('resetPasswordForm')"
          class="btn-block"
        >
          Reset
        </el-button>
      </el-row>
    </card-with-logo>
  </centered-layout>
</template>

<script>
  import { labels, rules } from "@/common";
  import ResetCredential from "@/models/resetCredential";
  import CenteredLayout from "@/components/layouts/CenteredLayout.vue";
  import CardWithLogo from "../components/CardWithLogo.vue";

  export default {
    components: {
      CenteredLayout,
      CardWithLogo,
    },

    data() {
      const confrimPassword = (rule, value, callback) => {
        if (value !== this.reset.password) {
          callback(new Error(labels.ER_PASSWORDS_MATCH));
        } else {
          callback();
        }
      };

      return {
        labels,
        rules: {
          ...rules,
          password_confirmation: [
            {
              required: true,
              message: labels.ER_CPASSWORD_REQUIRED,
              trigger: "blur",
            },
            {
              min: 8,
              message: labels.ER_PASSWORD_LENGTH,
              trigger: "blur",
            },
            { validator: confrimPassword, trigger: "blur" },
          ],
        },
        reset: new ResetCredential(),
      };
    },

    mounted() {
      this.setParams();
    },

    methods: {
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.dispatch("auth/resetPassword", this.reset).then(() => {
              this.$refs[formName].resetFields();
            });
          }
        });
      },

      goBack() {
        this.$router.go(-1);
      },

      setParams() {
        const { email, token } = this.$route.query;
        this.reset.email = email;
        this.reset.token = token;
      },
    },
  };
</script>

<style></style>
