<template>
  <div class="layout-wrapper">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "CenteredLayout",
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .layout-wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
